import type { GetStaticProps } from 'next'
import { useEffect } from 'react'

import Footer from '@src/components/footer'
import Head from '@src/components/head'
import Nav from '@src/components/nav'
import { eventService } from '@src/api/event'
import { Event } from '@api/types'
import PPVLandingPage from '@src/components/ppv-landing-page'
import { useRouter } from 'next/router'
import LoadingContainer from '@src/components/loading-container'
import { isServer } from '@src/lib/utils'

function PPVIndexPage(props: { event: Event }) {
  const router = useRouter()

  useEffect(() => {
    if (!isServer && !props?.event) {
      router.replace('/on-demand')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServer, props?.event])


  const { metadata, name } = props?.event ?? {}
  return (
    <>
      <Head
        title={`${name ?? 'Pay-Per-View'} | Barstool TV`}
        description={metadata?.social_share?.preview_text}
        ogImage={metadata?.social_share?.image}
      />
      <div className='flex flex-col ppv-index'>
        <div className='md:hidden'>
          <Nav type='ppv' />
        </div>
        <div className='fixed z-50 hidden w-full md:block'>
          <Nav type='ppv' />
        </div>
        <LoadingContainer loading={!props?.event}>
          <PPVLandingPage event={props?.event} />
        </LoadingContainer>
        <Footer image={''} />
      </div>
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const events = await eventService.list({
    type: 'barstool_tv_ppv',
    sort: 'date',
    status: 'on_demand,presale,upcoming,live,ended'
  })

  return {
    props: { event: events[0] || null },
    revalidate: 1
  }
}

export default PPVIndexPage
